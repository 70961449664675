<template>
  <main>
    <CRow align-horizontal="center">
      <CCol xl="6" lg="8">
        <CForm @submit.stop.prevent="SaveProfile()">
          <CCard>
            <CCardHeader>
              <strong>{{ $t('Navigation.Personal/Profile') }}</strong>
            </CCardHeader>
            <CCardBody>
              <CInput
                  :label="$t('Personal.Profile/CompanyProject')"
                  :value="$store.state.user.permission.ProjectTitle"
                  horizontal
                  plaintext
              />
              <CInput
                  :label="$t('Personal.Profile/Account')"
                  :value="$store.state.user.permission.Account"
                  horizontal
                  plaintext
              />
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Personal.Profile/EmailVerified') }}
                </CCol>
                <CCol sm="9" class="pt-2">
                  <CBadge :color="($store.state.user.data.emailVerified === true ? 'success' : 'danger')">
                    {{
                      $store.state.user.data.emailVerified === true ?
                          $t('Personal.Profile/Verified') : $t('Personal.Profile/Unverified')
                    }}
                  </CBadge>
                </CCol>
              </CRow>
              <CInput
                  :label="$t('Personal.Profile/Avatar')"
                  v-model="user.data.photoURL"
                  horizontal
                  autocomplete="photoURL"
              />
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label" />
                <CCol sm="9">
                  <img :src="user.data.photoURL" height="50" alt="avatar">
                </CCol>
              </CRow>
              <CInput
                  :label="$t('Personal.Profile/Name')"
                  :description="$t('Personal.Profile/NameDescription')"
                  v-model="user.data.displayName"
                  horizontal
                  autocomplete="name"
              />
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Personal.Profile/Password') }}
                </CCol>
                <CCol sm="9">
                  <CButton color="dark" size="sm" class="mt-1" @click="SetPasswordModal = true">
                    {{ $t('Personal.Profile/ChangePassword') }}
                  </CButton>
                </CCol>
              </CRow>
              <CInput
                  :label="$t('Personal.Profile/Identity')"
                  :value="$t('Organization/Manager.IdentityOptions.' + $store.state.user.permission.Level)"
                  horizontal
                  plaintext
              />
              <CInput
                  :label="$t('Personal.Profile/LastSignInTime')"
                  :value="$store.state.user.data.metadata.lastSignInTime"
                  horizontal
                  plaintext
              />
            </CCardBody>
            <CCardFooter>
              <CButton type="submit" size="sm" color="primary">
                <CIcon name="cil-check-circle"/>
                {{ $t('Global.Submit') }}
              </CButton>
            </CCardFooter>
          </CCard>
        </CForm>
      </CCol>
    </CRow>
    <CModal
        :show.sync="SetPasswordModal"
        :centered="true"
        :title="$t('Login.ResetPassword')"
        color="dark"
    >
      <CForm>
        <CInput
            v-model="Password"
            :placeholder="$t('Personal.Profile/Password')"
            type="password"
            autocomplete="current-password"
        >
        </CInput>
      </CForm>
      <template #footer>
        <CButton @click="SetPasswordModal = false" color="secondary">{{ $t('Global.Cancel') }}</CButton>
        <CButton @click="SetPassword()" color="success">{{ $t('Global.Confirm') }}</CButton>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "個人設定"
}
}
</route>

<script>
import {mapState} from 'vuex'

export default {
  name: 'Dashboard',
  layout: 'manage',
  computed: {
    ...mapState([
      'user'
    ])
  },
  data() {
    return {
      SetPasswordModal: false,
      Password: ''
    }
  },
  mounted() {
  },
  methods: {
    SaveProfile() {
      this.$Firebase('auth').currentUser.updateProfile({
        displayName: this.user.data.displayName,
        photoURL: this.user.data.photoURL
      }).then(() => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Profile/ChangeProfileSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err,
          type: 'error'
        })
      });
    },
    SetPassword() {
      this.$Firebase('auth').currentUser.updatePassword(this.Password).then(() => {
        this.SetPasswordModal = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Profile/ChangePasswordSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err,
          type: 'error'
        })
      })
    }
  }
}
</script>
